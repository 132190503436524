import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

const MyDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown nav isOpen={dropdownOpen} toggle={toggle} className="ms-auto">
      <DropdownToggle nav caret>
        Media
      </DropdownToggle>
      <DropdownMenu>
        <Link to="/greeeting-meterial" >
          <DropdownItem style={{ width: "100%", color: "tomato" }}>
            Articles
          </DropdownItem>
        </Link>
        <Link to="/media">
          <DropdownItem style={{ width: "100%" }}>Gallery</DropdownItem>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MyDropdown;
