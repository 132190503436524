import pdf1 from '../../assets/pdf/doc-1.pdf'
import pdf10 from '../../assets/pdf/doc-10.pdf'
import pdf11 from '../../assets/pdf/doc-11.pdf'
import pdf12 from '../../assets/pdf/doc-12.pdf'
import pdf15 from '../../assets/pdf/doc-15.pdf'
import pdf2 from '../../assets/pdf/doc-2.pdf'
import pdf3 from '../../assets/pdf/doc-3.pdf'
import pdf6 from '../../assets/pdf/doc-6.pdf'
import pdf7 from '../../assets/pdf/doc-7.pdf'
import pdf8 from '../../assets/pdf/doc-8.pdf'
import pdf9 from '../../assets/pdf/doc-9.pdf'
import pdf20 from '../../assets/pdf/doc-20.pdf'
import pdf21 from '../../assets/pdf/doc-21.pdf'
import pdf22 from '../../assets/pdf/doc-22.pdf'
import pdf23 from '../../assets/pdf/doc-23.pdf'
import pdf24 from '../../assets/pdf/doc-24.pdf'
import pdf25 from '../../assets/pdf/doc-25.pdf'
import pdf26 from '../../assets/pdf/doc-26.pdf'
import pdf27 from '../../assets/pdf/doc-27.pdf'
import pdf28 from '../../assets/pdf/doc-28.pdf'
import pdf29 from '../../assets/pdf/doc-29.pdf'
import pdf30 from '../../assets/pdf/doc-30.pdf'
import pdf31 from '../../assets/pdf/doc-31.pdf'
import pdf32 from '../../assets/pdf/doc-32.pdf'
import pdf33 from '../../assets/pdf/doc-33.pdf'
import pdf34 from '../../assets/pdf/doc-34.pdf'
import pdf35 from '../../assets/pdf/doc-35.pdf'
import pdf36 from '../../assets/pdf/doc-36.pdf'
import pdf37 from '../../assets/pdf/doc-37.pdf'
import pdf38 from '../../assets/pdf/doc-38.pdf'





export const data = [

    {
        path: pdf38
        // title: "Dr. Rajitha De Silva"
    },

    {
        path: pdf37
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf36
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf35
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf34
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf33
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf32
        // title: "Dr. Rajitha De Silva"
    },
    
    {
        path: pdf31
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf11,
        // title: "Dr. Rajitha De Silva"
    },

    {
        path: pdf28
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf29
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf23
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf24
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf26
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf25
        // title: "Dr. Rajitha De Silva"
    }, 
    {
        path: pdf21
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf20
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf22
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf1,
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf2,
        // title: 'Shanti Fernando'
    },
    {
        path: pdf3,
        // title: 'Give a Heart,Save a Life'
    },
    {
        path: pdf6,
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf7,
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf8,
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf9,
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf15,
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf12,
        // title: "Dr. Rajitha De Silva"
    },
    {
        path: pdf10,
        // title: "Dr. Rajitha De Silva"
    },
    
    {
        path: pdf30
        // title: "Dr. Rajitha De Silva"
    },
   
    
    
    
]