import PropTypes from "prop-types";
import React, { useState } from 'react';
import PDF from "react-pdf-js";
import { Button, Card, Container } from 'reactstrap';
import useDeviceDetection from '../../hooks/useDeviceDetection';
import PdfViewer from './PdfViewer';
import { data } from './data';
const GreetingList = () => {
    const [selectedPdf, setSelectedPdf] = useState(null);

    const handleClick = (pdfPath) => {
        setSelectedPdf(pdfPath);
    };
    const device = useDeviceDetection();
    return (
        <div className="simple-container">
            <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>
                {data.map((item, index) => (
                    <Card className='pt-5 card-shadow ' key={index} >
                        <h3 style={{ textAlign: 'center' }}>{item.title}</h3>
                        <PDF
                            scale={device == 'Mobile' ? 0.7 : 0.8}
                            file={item.path}
                        />
                        <div className="text-center">
                            <Button className='btn-basic-org mb-3 font-15 pl-5' onClick={() => handleClick(item.path)}>View Details</Button>
                        </div>
                    </Card>
                ))}

            </div>

            <PdfViewer pdf={selectedPdf} onCancel={() => setSelectedPdf(null)} visible={!!selectedPdf} />
        </div>
    );
};

GreetingList.propTypes = {
    classes: PropTypes.object
};

export default GreetingList;
